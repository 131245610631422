import "../assets/sass/style.scss"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import Header from "./header"
import Footer from "./footer"

const Layout = ({
  children,
  isNews,
  wider,
  language,
  lang,
  translationEN,
  translationDE,
  translationES,
  translationIT,
  translationFR,
}) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfEhuIZAAAAAG_nF7-tItMFSNOL3w0teYooVSWf">
      <Header
        language={language}
        lang={lang}
        isNews={isNews}
        translationEN={translationEN}
        translationDE={translationDE}
        translationES={translationES}
        translationIT={translationIT}
        translationFR={translationFR}
      />
      <main className={wider ? "wider" : ""}>{children}</main>
      <Footer lang={lang} />
      {/* {cookieBaner && (
          <div className={`cookies-baner`}>
            <div className="cookies-baner__container">
              <p className="cookies-baner__text">
                {t(
                  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.",
                  lang
                )}
              </p>
              <div className="buttons-container">
                <button className="btn btn--white" onClick={acceptCookieBaner}>
                  {t("Accept", lang)}
                </button>
                <a
                  href={t("/cookies-policy/", lang)}
                  className="btn btn--white"
                >
                  {t("Learn more", lang)}
                </a>
              </div>
            </div>
          </div>
        )} */}
    </GoogleReCaptchaProvider>
  )
}

export default Layout
