import React from "react"
import { Link } from "gatsby"

import t from "locale"

const Footer = ({ lang }) => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-lg-1 footer-col">
              <h3 className="footer__title">{t("Contact", lang)}</h3>
              <p className="footer__name">HIDDENFJORD</p>
              <div className="footer__contact-container contact-container">
                <p className="contact-container__text">
                  {t("Phone", lang)}: <a href="tel:+298662100">+298 662100</a>
                </p>
                <p className="contact-container__text">
                  {t("Email", lang)}:{" "}
                  <a href="mailto:sales@hiddenfjord.com">
                    sales@hiddenfjord.com
                  </a>
                </p>
                <p className="contact-container__text">
                  {t("Administration", lang)}:{" "}
                  <a href="mailto:mail@hiddenfjord.com">mail@hiddenfjord.com</a>
                </p>
              </div>
              <p className="footer__trademark">
                {t("Hiddenfjord is a trademark of P/F Luna", lang)}
              </p>
            </div>
            <div className="col-md-3 footer-col">
              <ul className="footer__list">
                <li>P/F Luna</li>
                <li>Á Fútakletti</li>
                <li>360 Sandavágur</li>
                <li>Faroe Islands</li>
                <li className="empty"></li>
                <li>Reg no: 2258</li>
                <li>VAT no: 418285</li>
              </ul>
              <div className="footer__icons">
                <Link to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions">
                  <img
                    src={require("../assets/images/hero-ico-1.png")}
                    className="footer__icon img-fluid"
                    alt=""
                  />
                </Link>
                <Link
                  to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions"
                  style={{ marginLeft: 40 }}
                >
                  <img
                    src={require("../assets/images/hero-ico-2.png")}
                    className="footer__icon img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-col">
              <p className="footer__title title--small">
                {t("Mail address", lang)}:
              </p>
              <ul className="footer__list">
                <li>PO Box 29</li>
                <li>510 Gøta</li>
                <li>Faroe Islands</li>
                <li className="empty"></li>
                <li>
                  <Link href="/privacy-notice">Privacy Notice</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__copy">
          <div className="copy-container">
            <a
              href="http://hogra.fo/"
              target="_blank"
              rel="noreferrer"
              className="copy-container__text"
            >
              {t("website by hogra.fo", lang)}
            </a>
            {/* <div className="copy-container__socials-container socials-container">
              <a
                href="/"
                className="socials-container__icon-link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../assets/images/ico-twitter.png")}
                  alt=""
                  className="socials-container__icon"
                />
              </a>
              <a
                href="https://www.facebook.com/hiddenfjord/"
                className="socials-container__icon-link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../assets/images/ico-facebook.png")}
                  alt=""
                  className="socials-container__icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/hiddenfjord"
                className="socials-container__icon-link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../assets/images/ico-linkedin.png")}
                  alt=""
                  className="socials-container__icon"
                />
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
