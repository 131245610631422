const tranlations_de = {
  // Cookies
  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.":
    "Unsere Website verwendet Cookies für die personalisierte Anzeige von Inhalten sowie zur Analyse von Website Traffic. Weitere Informationen finden Sie in unseren Cookie-Richtlinien.",
  Accept: "Zustimmen",
  "Learn more": "Weitere Informationen",
  "/cookies-policy/": "/de/cookies-policy/",
  Close: "Close",

  // Header
  "/": "/de/",
  "/globalg-a-p/": "/de/globalg-a-p/",
  "GlobalG.A.P": "GLOBALG.A.P.",
  Certifications: "Zertifizierungen",
  "/certifications/": "/de/zertifizierungen/",
  "/our-products/": "/de/produkte/",
  "Our products": "PRODUKTE",
  "/our-company/": "/de/uber-hiddenfjord/",
  "Our Company": "ÜBER HIDDENFJORD",
  "/contact/": "/de/kontakt/",
  Contact: "Kontakt",
  "/quality-choice/": "/de/qualitat/",
  "Quality choice": "QUALITÄT",
  "/raised-in-the-wild/": "/de/im-offenen-meer-aufgezogen/",
  "Raised in the wild": "IM OFFENEN MEER AUFGEZOGEN",
  "Exceptional taste": "Außergewöhnlicher Geschmack",
  "/truly-sustainable-farming/": "/de/wirklich-nachaltige-aufzucht/",
  "Truly sustainable farming": "Wirklich nachhaltige Aufzucht",
  "/animal-welfare/": "/de/tierwohl/",
  "Animal welfare": "TIERWOHL",
  "/low-emission-shipment/": "/de/emissionsarmer-transport/",
  "Low-emission shipment": "Emissionsarmer Transport",
  "/home-of-the-atlantic-salmon/": "/de/hemat-des-atlantischen-lachses/ ",
  "Home of the Atlantic Salmon": "Heimat des Atlantischen Lachses",
  "/news/": "/de/news/",
  News: "News",
  "/jobs/": "/de/arbeitsplaetze/",
  Jobs: "Arbeitsplätze",

  // Footer & Contact page
  // Phone: "Telefon",
  // Email: "Email",
  // Sales: "Vertrieb",
  // Administration: "Administration",
  // "Message form": "Kontaktformular",
  // "All Departments": "All Departments",
  // "Hiddenfjord is a trademark of P/F Luna":
  //   "Hiddenfjord ist eine Marke der P/F Luna",
  // "Mail address": "Mail address",
  // "website by hogra.fo": "webseite by hogra.fo",
  // "Claim form": "Claim form",

  // Contact
  "First name": "First name",
  "Last Name": "Last Name",
  Address: "Address",
  "ZIP code": "ZIP code",
  Town: "Town",
  Country: "Country",
  Telephone: "Telephone",
  Description: "Description",
  "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "/privacy-policy/": "/de/privacy-policy/",
  "Privacy Policy": "Privacy Policy",
  SUBMIT: "SUBMIT",
  "Sending...": "Sending...",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  // News
  Newest: "Newest",
  "Read more": "Mehr",
  "See animation": "Animation ansehen",

  // Home
  "Salmon from the Faroe Islands": "Lachs von den Färöern",
  SUSTAINABILITY: "NACHHALTIGKEIT",
  QUALITY: "QUALITÄT",
  Days: "TAGEN",
  ago: "",
  "CO2-emissions from meat production": "CO2-Austoß der Fleischproduktion",
  "kg CO2 per kg": "kg CO2 pro kg ",

  // Diagrams
  "Sea freight to US": "Seefracht in die USA",
  "Air freight New York": "Luftfracht New York",
  "Air freight Shanghai": "Luftfracht Shanghai",
  Production: "Produktion",
  Freight: "Fracht",
  "-emissions per transport type/distance:":
    "-Ausstoß pro Transportart/Entfernung:",
  "edible meat": "essbaren Fleisches",

  "% of Hiddenfjord salmon transported by air":
    "Hiddenfjord-Lachs transportiert mit Luftfracht in %",

  "-emissions from meat production": "-Austoß der Fleischproduktion",

  //
  "The <span>Healthy</span> Choice": "Die gesunde <span>Wahl</span>",
  "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.":
    "Lachs ist nahrhaft und reich an Omega-3-Fettsäuren, Vitaminen und Proteinen. Hiddenfjord Lachs ist frei von Antibiotika und Hormonen.",

  "Raised in the <span>Wild</span>": "IM OFFENEN MEER <span>AUFGEZOGEN</span>",
  "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.":
    "Unser Lachs wird in den klaren Gewässern rund um die Färöer aufgezogen. Die Aufzucht in Netzgehegen im offenen Meer mit starker Strömung und hohen Wellen hält den Lachs fit und verhindert die Verschmutzung des Meeresbodens.",

  "Exceptional <span>Taste</span>": "Außergewöhnlicher <span>Geschmack</span>",
  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.":
    "Hiddenfjord Lachs ist von außerordentlicher Qualität. Er hat ein klares Meeresaroma. Er zergeht förmlich wie Butter auf der Zunge.",
  "Dave Pasternack, Chef": "Dave Pasternack, Koch, New York",
  "ESCA Restaurant, New York": "ESCA Restaurant, New York",
  "See video": "Zum Video",

  "Truly <span>Sustainable</span> Farming":
    "Wirklich nachhaltige <span>Aufzucht</span>",
  "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.":
    "Hiddenfjord Lachs wird in einem Gebiet aufgezogen, das zu den wildesten weltweit zählt und in dem optimale Zuchtbedingungen herrschen. Nachhaltigkeit ist unser Hauptaugenmerk und spiegelt sich in all unserem Handeln wider.",

  "<span>Stress-free</span> Harvesting": "<span>Stressfreie</span> Ernte",
  "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.":
    "Unser einzigartiges Konzept nutzt den natürlichen Instinkt des Lachses, gegen den Strom zu schwimmen. Der Lachs wird vorsichtig von den Netzgehegen aus dem offenen Meer in die Erntebecken geleitet und stressfrei betäubt. Dies sorgt für einen besseren Geschmack und eine längere Haltbarkeit.",

  "<span>Low-emission</span> <br /> Shipment":
    "<span>Emissionsarmer</span> <br /> Transport",
  "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.":
    "Am 10. Oktober 2020 stellten wir den Transport unserer Produkte via Luftfracht ein. In dem wir einen der Haupttreiber des globalen CO<sub>2</sub>-Ausstoßes durch Seefracht ersetzten, reduzierten wir unseren CO<sub>2</sub>-Fußabdruck um 94%",

  "<span>Home</span> <br /> of the Atlantic Salmon":
    "<span>Heimat</span> <br/> des Atlantischen Lachses",
  "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.":
    "Seit tausenden Jahren zieht der Atlantische Lachs in die Gewässer rund um die Färöer. Er findet hier optimale Lebensbedingungen. Es ist die natürliche Heimat des Lachses.",

  "A <span>Family</span> Business": "Ein <span>Familienunternehmen</span>",
  "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.":
    "Hiddenfjord ist ein Familienunternehmen, das 1929 gegründet wurde. Unser Hauptaugenmerk gilt der größtmöglichen Umweltverträglichkeit.",
  "The brothers, Atli and Regin Gregersen, manage the company today.":
    "Die Brüder Atli und Regin Gregersen leiten heute das Unternehmen.",

  //
  // "Sea freight to US": "Sea freight to US",
  // "Air freight New York": "Air freight New York",
  // Freight: "Freight",
  // Production: "Production",
  // "Air freight Shanghai": "Air freight Shanghai",
  // "-emissions per transport type/distance:":
  //   "-emissions per transport type/distance:",
  "kg CO": "kg CO",
  per: "pro",
  "kg HOG": "kg lachs (HOG)",
}
export default tranlations_de
