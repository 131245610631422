const tranlations_it = {
  // Cookies
  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.":
    "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.",
  Accept: "Accept",
  "Learn more": "Learn more",
  "/cookies-policy/": "/it/cookies-policy/",
  Close: "Close",

  // Header
  "/": "/it/",
  "/globalg-a-p/": "/it/globalg-a-p/",
  "GlobalG.A.P": "GlobalG.A.P",
  Certifications: "Certificazioni di Qualità",
  "/certifications/": "/it/certificazioni-di-qualita/",
  "/our-products/": "/it/i-nostri-prodotti/",
  "Our products": "I NOSTRI PRODOTTI",
  "/our-company/": "/it/la-nostra-azienda/",
  "Our Company": "LA NOSTRA AZIENDA",
  "/contact/": "/it/contatti/",
  Contact: "Contatti",
  "/quality-choice/": "/it/la-qualita-come-scelta/",
  "Quality choice": "La scelta salutare",
  "/raised-in-the-wild/": "/it/allevato-nel-suo-ambiente-naturale/",
  "Raised in the wild": "Allevato nel suo ambiente naturale",
  "Exceptional taste": "Un sapore eccezionale",
  "/truly-sustainable-farming/": "/it/un-autentico-allveamento-sosteinibile/",
  "Truly sustainable farming":
    "Un metodo d'allevamento autenticamente sostenibile",
  "/animal-welfare/": "/it/il-benessere-degli-animali/",
  "Animal welfare": "BENESSERE DEGLI ANIMALI",
  "/low-emission-shipment/": "/it/basse-emissioni-di-spedizione/",
  "Low-emission shipment": "BASSE EMISSIONI DI SPEDIZION",
  "/home-of-the-atlantic-salmon/":
    "/it/la-casa-naturale-del-salmone-atlantico/",
  "Home of the Atlantic Salmon": "La casa del salmone atlantico",
  "/news/": "/it/news/",
  News: "News",
  "/jobs/": "/it/lavori/",
  Jobs: "Lavori",

  // Footer & Contact page
  Phone: "Phone",
  Email: "Email",
  Sales: "Ufficio vendite",
  Administration: "Amministrazione",
  "Message form": "Message form",
  "All Departments": "All Departments",
  "Hiddenfjord is a trademark of P/F Luna":
    "Hiddenfjord is a trademark of P/F Luna",
  "Mail address": "Mail address",
  "website by hogra.fo": "sito web hogra.fo",
  "Claim form": "Claim form",

  // Contact
  "First name": "First name",
  "Last Name": "Last Name",
  Address: "Address",
  "ZIP code": "ZIP code",
  Town: "Town",
  Country: "Country",
  Telephone: "Telefono",
  Description: "Description",
  "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "/privacy-policy/": "/it/privacy-policy/",
  "Privacy Policy": "Privacy Policy",
  SUBMIT: "SUBMIT",
  "Sending...": "Sending...",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  // News
  Newest: "Newest",
  "Read more": "Leggi di più",
  "See animation": "Vedi animazione",

  // Home
  "Salmon from the Faroe Islands": "Il salmone delle Isole Faroer",
  SUSTAINABILITY: "SOSTENIBILITÀ",
  QUALITY: "QUALITÀ",
  Days: "giorni",
  ago: "",

  // Diagrams
  "Sea freight to US": "Trasporto via mare agli USA",
  "Air freight New York": "Trasporto aereo per New York",
  "Air freight Shanghai": "Trasporto aereo per Shanghai",
  Freight: "Transporto",
  "-emissions per transport type/distance:":
    "-emissioni di CO2 per trasporto tipo/distanza:",
  "edible meat": "di carne edibile",

  "% of Hiddenfjord salmon transported by air":
    "di salmone Hiddenfjord trasportato per via aerea",

  "-emissions from meat production": "-emissioni dalla produzione di carne",

  //
  "The <span>Healthy</span> Choice": "La <span>scelta</span> salutare",
  "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.":
    "Il salmone è un alimento nutriente con un ricco contenuto di acidi grassi polinsaturi Omega 3, vitamine e proteine. Il salmone Hiddenfjord non contiene antibiotici od ormoni.",

  "Raised in the <span>Wild</span>":
    "Allevato nel suo ambiente <span>naturale</span>",
  "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.":
    "Il nostro salmone cresce nelle acque incontaminate attorno alle Isole Faroer. L'allevamento in gabbie galleggianti situate in oceano aperto percorse da forti correnti e alte onde mantiene sano il salmone e previene l'inquinamento del fondale marino.",

  "Exceptional <span>Taste</span>": "Un sapore <span>eccezionale</span>",
  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.":
    "Il salmone Hiddenfjord è di una qualità fantastica. Ha un gusto d'oceano perfetto. Una deliziosa morbidezza che indugia delicatamente sul palato.",
  "Dave Pasternack, Chef, New York": "Dave Pasternack, Capocuoco, New York",
  "ESCA Restaurant, New York": "ESCA Restaurant, New York",
  "See video": "Vedi video",

  "Truly <span>Sustainable</span> Farming":
    "Un metodo d'allevamento autenticamente <span>sostenibile</span>",
  "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.":
    "Il salmone Hiddenfjord cresce in allevamenti situati in mare aperto che assicurano le migliori condizioni naturali possibili al mondo. La sostenibilità è per noi un principio fondamentale e si riflette in tutti gli aspetti del nostro processo produttivo.",

  "<span>Stress-free</span> Harvesting":
    "Condizioni d'allevamento <span>prive di stress</span>",
  "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.":
    "Il nostro approccio produttivo è unico dato che utilizza l'istinto naturale dei salmoni a nuotare contro la corrente. Essi vengono accompagnati attentamente dalle gabbie in mare aperto ai bacini di raccolta e abbattuti senza stress. Una pratica che conferisce alla loro carne un sapore migliore e una più lunga durata a scaffale.",

  "<span>Low-emission</span> <br /> Shipment":
    "<span>Basse emissioni </span> di spedizione",
  "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.":
    "Dal 10 ottobre 2020 non utilizziamo più il trasporto aereo, che abbiamo sostituito con quello via mare contribuendo così alla riduzione del 94% delle emissioni globali di CO<sub>2</sub>.",

  "<span>Home</span> <br /> of the Atlantic Salmon":
    "<span>La casa</span> del salmone atlantico",
  "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.":
    "Per migliaia di anni il salmone atlantico è migrato nelle acque attorno alle Isole Faroer per cibarsi e crescere, adattandosi così a queste acque incontaminate. Facendone la sua dimora.",

  "A <span>Family</span> Business": "Una azienda <span>di famiglia</span>",
  "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.":
    "Hiddenfjord è una azienda a conduzione famigliare fondata nel 1929. Nella sua gestione noi abbiamo una linea di condotta idealista riferita alla sostenibilità ambientale.",
  "The brothers, Atli and Regin Gregersen, manage the company today.":
    "I fratelli Atli e Regin Gregersen dirigono l'azienda.",

  //
  // "Sea freight to US": "Sea freight to US",
  // "Air freight New York": "Air freight New York",
  // Freight: "Freight",
  Production: "Produzione",
  // "Air freight Shanghai": "Air freight Shanghai",
  // "-emissions per transport type/distance:":
  //   "-emissions per transport type/distance:",
  "kg CO": "kg CO",
  per: "per",
  "kg HOG": "kg di salmone (HOG)",
}
export default tranlations_it
