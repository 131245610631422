import tranlations_en from "./en"
import tranlations_de from "./de"
import tranlations_es from "./es"
import tranlations_it from "./it"
import tranlations_fr from "./fr"

const t = (name, lang = "en") => {
  lang = lang.toLowerCase()
  try {
    switch (lang) {
      case "en":
        return tranlations_en[name] || name
      case "de":
        return tranlations_de[name] || name
      case "es":
        return tranlations_es[name] || name
      case "it":
        return tranlations_it[name] || name
      case "fr":
        return tranlations_fr[name] || name
      default:
        return name
    }
  } catch (error) {
    return name
  }
}
export default t
