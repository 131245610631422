const tranlations_es = {
  // Cookies
  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.":
    "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.",
  Accept: "Accept",
  "Learn more": "Learn more",
  "/cookies-policy/": "/es/cookies-policy/",
  Close: "Close",

  // Header
  "/": "/es/",
  "/globalg-a-p/": "/es/globalg-a-p/",
  "GlobalG.A.P": "GlobalG.A.P",
  Certifications: "Certificaciones",
  "/certifications/": "/es/certificaciones/",
  "/our-products/": "/es/nuestros-productos/",
  "Our products": "NUESTROS PRODUCTOS",
  "/our-company/": "/es/una-emepresa-familiar/",
  "Our Company": "UNA EMPRESA FAMILIAR",
  "/contact/": "/es/contact/",
  Contact: "Contact",
  "/quality-choice/": "/es/eleccion-de-calidad/",
  "Quality choice": "ELECCIÓN DE CALIDAD",
  "/raised-in-the-wild/": "/es/criado-en-la-naturaleza/",
  "Raised in the wild": "CRIADO EN LA NATURALEZA",
  "Exceptional taste": "Sobor excepcional",
  "/truly-sustainable-farming/": "/es/piscicultura-verdaderamente-sostenible/",
  "Truly sustainable farming": "PISCICULTURA VERDADERAMENTE SOSTENIBLE",
  "/animal-welfare/": "/es/bienestar-de-animales/",
  "Animal welfare": "BIENESTAR DE ANIMALES",
  "/low-emission-shipment/": "/es/transporte-de-bajas-emisiones/",
  "Low-emission shipment": "Transporte de bajas emisiones ",
  "/home-of-the-atlantic-salmon/": "/es/hogar-del-salmon-del-atlantico/",
  "Home of the Atlantic Salmon": "HOGAR DEL SALMÓN DEL ATLÁNTICO",
  "/news/": "/es/news/",
  News: "News",
  "/jobs/": "/es/trabajos/",
  Jobs: "Trabajos",

  // Footer & Contact page
  Phone: "Phone",
  Email: "Email",
  Sales: "Sales",
  Administration: "Administration",
  "Message form": "Message form",
  "All Departments": "All Departments",
  "Hiddenfjord is a trademark of P/F Luna":
    "Hiddenfjord is a trademark of P/F Luna",
  "Mail address": "Mail address",
  "website by hogra.fo": "sitio web hogra.fo",
  "Claim form": "Claim form",

  // Contact
  "First name": "First name",
  "Last Name": "Last Name",
  Address: "Address",
  "ZIP code": "ZIP code",
  Town: "Town",
  Country: "Country",
  Telephone: "Telephone",
  Description: "Description",
  "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "/privacy-policy/": "/es/privacy-policy/",
  "Privacy Policy": "Privacy Policy",
  SUBMIT: "SUBMIT",
  "Sending...": "Sending...",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  // News
  Newest: "Newest",
  "Read more": "Leer más",
  "See animation": "Ver animación",

  // Home
  "Salmon from the Faroe Islands": "Salmón de las Islas Feroe",
  SUSTAINABILITY: "SOSTENIBILIDAD",
  QUALITY: "CALIDAD",
  Days: "DIAS",
  ago: "",

  // Diagrams
  "Sea freight to US": "Flete marítimo a US",
  "Air freight New York": "Flete aereo a New York",
  "Air freight Shanghai": "Flete aereo a Shanghai",
  Production: "Producción",
  Freight: "Transporte",
  "-emissions per transport type/distance:":
    "-emisiones por tipo de transporte/distancia",
  "edible meat": "de carne comestible",

  "% of Hiddenfjord salmon transported by air":
    "% of Hiddenfjord salmon transported by air",

  "-emissions from meat production":
    "-Emisiones de CO2 de la producción de carne",

  //
  "The <span>Healthy</span> Choice": "La <span>elección</span> saludable",
  "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.":
    "El salmón es una comida nutritiva con un rico contenido de ácidos grasos omega-3, vitaminas y proteínas. El salmón Hiddenfjord no contiene antibióticos ni hormonas.",

  "Raised in the <span>Wild</span>": "CRIADO EN LA <span>NATURALEZA</span>",
  "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.":
    "Nuestro salmón se cría en las aguas cristalinas alrededor de las Islas Feroe. El cultivo en corrales en mar abierto con fuertes corrientes y olas altas mantiene el salmón en forma y evita la contaminación del fondo marino.",

  "Exceptional <span>Taste</span>": "<span>Sabor</span> excepcional",
  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.":
    "El salmón Hiddenfjord tiene una calidad fantástica. Tiene un sabor oceánico limpio. Se queda limpiamente en el paladar y tiene una muy buena grasa",
  "Dave Pasternack, Chef, New York": "Dave Pasternack, Chef, Nueva York",
  "ESCA Restaurant, New York": "ESCA Restaurant, New York",
  "See video": "Ver video",

  "Truly <span>Sustainable</span> Farming":
    "Piscicultura <span>verdaderamente</span> sostenible",
  "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.":
    "El salmón Hiddenfjord se cría en los lugares de cultivo más expuestos del mundo para garantizar condiciones óptimas de cultivo. La sostenibilidad es nuestro valor fundamental y esto se refleja en todas nuestras acciones.",

  "<span>Stress-free</span> Harvesting": "Cosecha <span>sin estrés</span>",
  "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.":
    "Nuestro concepto único utiliza el instinto natural del salmón para nadar contra la corriente. Son guiados cuidadosamente desde los corrales de mar abierto hasta el corral de cosecha y aturdidos sin estrés. Esto da un mejor sabor y una vida de anaquel más larga.",

  "<span>Low-emission</span> <br /> Shipment":
    "Transporte de <span>bajas emisiones</span>",
  "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.":
    "El 10-10-2020 detuvimos todo nuestro flete aéreo. Al reemplazar este importante contribuyente a las emisiones globales de CO<sub>2</sub> con el transporte marítimo hemos reducido nuestra huella de carbono para el transporte al extranjero en un 94%.",

  "<span>Home</span> <br /> of the Atlantic Salmon":
    "<span>HOGAR</span> <br/> DEL SALMÓN DEL ATLÁNTICO",
  "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.":
    "Durante miles de años, el salmón del Atlántico ha migrado al mar alrededor de las Islas Feroe para alimentarse y crecer y, por lo tanto, se ha adaptado a estas aguas prístinas. Este es el hogar natural del salmón. ",

  "A <span>Family</span> Business": "Una empresa <span>familiar</span>",
  "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.":
    "Hiddenfjord es una empresa familiar fundada en 1929. Tenemos un enfoque idealista en la sostenibilidad ambiental. ",
  "The brothers, Atli and Regin Gregersen, manage the company today.":
    "Los hermanos, Atli y Regin Gregersen, dirigen la empresa hoy en día.",

  //
  // "Sea freight to US": "Sea freight to US",
  // "Air freight New York": "Air freight New York",
  // Freight: "Freight",
  // Production: "Production",
  // "Air freight Shanghai": "Air freight Shanghai",
  // "-emissions per transport type/distance:":
  //   "-emissions per transport type/distance:",
  "kg CO": "kg CO",
  per: "por",
  "kg HOG": "kg salmón (HOG)",
}
export default tranlations_es
