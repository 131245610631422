const tranlations_en = {
  // Cookies
  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.":
    "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.",
  Accept: "Accept",
  "Learn more": "Learn more",
  "/cookies-policy/": "/cookies-policy/",
  Close: "Close",

  // Header
  "/": "/",
  "/globalg-a-p/": "/globalg-a-p/",
  "GlobalG.A.P": "GlobalG.A.P",
  Certifications: "Certifications",
  "/our-products/": "/our-products/",
  "Our products": "Our products",
  "/our-company/": "/our-company/",
  "Our Company": "Our Company",
  "/contact/": "/contact/",
  Contact: "Contact",
  "/quality-choice/": "/quality-choice/",
  "Quality choice": "Quality choice",
  "/raised-in-the-wild/": "/raised-in-the-wild/",
  "Raised in the wild": "Raised in the wild",
  "Exceptional taste": "Exceptional taste",
  "/truly-sustainable-farming/": "/truly-sustainable-farming/",
  "Truly sustainable farming": "Truly sustainable farming",
  "/animal-welfare/": "/animal-welfare/",
  "Animal welfare": "Animal welfare",
  "/low-emission-shipment/": "/low-emission-shipment/",
  "Low-emission shipment": "Low-emission shipment",
  "/home-of-the-atlantic-salmon/": "/home-of-the-atlantic-salmon/",
  "Home of the Atlantic Salmon": "Home of the Atlantic Salmon",
  "/news/": "/news/",
  News: "News",
  "/jobs/": "/jobs/",
  Jobs: "Jobs",

  // Footer & Contact page
  Phone: "Phone",
  Email: "Email",
  Sales: "Sales",
  Administration: "Administration",
  "Message form": "Message form",
  "All Departments": "All Departments",
  "Hiddenfjord is a trademark of P/F Luna":
    "Hiddenfjord is a trademark of P/F Luna",
  "Mail address": "Mail address",
  "website by hogra.fo": "website by hogra.fo",
  "Claim form": "Claim form",

  // Contact
  "First name": "First name",
  "Last Name": "Last Name",
  Address: "Address",
  "ZIP code": "ZIP code",
  Town: "Town",
  Country: "Country",
  Telephone: "Telephone",
  Description: "Description",
  "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "/privacy-policy/": "/privacy-policy/",
  "Privacy Policy": "Privacy Policy",
  SUBMIT: "SUBMIT",
  "Sending...": "Sending...",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  // News
  Newest: "Newest",
  "Read more": "Read more",

  // Home
  "Salmon from the Faroe Islands": "Salmon from the Faroe Islands",
  SUSTAINABILITY: "SUSTAINABILITY",
  QUALITY: "QUALITY",
  Days: "Days",
  ago: "ago",
  "CO2-emissions from meat production": "CO2-emissions from meat production",

  // Diagrams
  "Sea freight to US": "Sea freight to US",
  "Air freight New York": "Air freight New York",
  "Air freight Shanghai": "Air freight Shanghai",
  Freight: "Freight",
  "-emissions per transport type/distance:":
    "-emissions per transport type/distance:",
  "edible meat": "edible meat",

  "% of Hiddenfjord salmon transported by air":
    "% of Hiddenfjord salmon transported by air",

  "-emissions from meat production": "-emissions from meat production",

  //
  "The <span>Healthy</span> Choice": "The <span>Healthy</span> Choice",
  "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.":
    "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.",

  "Raised in the <span>Wild</span>": "Raised in the <span>Wild</span>",
  "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.":
    "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.",

  "Exceptional <span>Taste</span>": "Exceptional <span>Taste</span>",
  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.":
    "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.",
  "Dave Pasternack, Chef, New York": "Dave Pasternack, Chef, New York",
  "ESCA Restaurant, New York": "ESCA Restaurant, New York",
  "See video": "See video",

  "Truly <span>Sustainable</span> Farming":
    "Truly <span>Sustainable</span> Farming",
  "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.":
    "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.",

  "<span>Stress-free</span> Harvesting": "<span>Stress-free</span> Harvesting",
  "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.":
    "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.",

  "<span>Low-emission</span> <br /> Shipment":
    "<span>Low-emission</span> <br /> Shipment",
  "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.":
    "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.",

  "<span>Home</span> <br /> of the Atlantic Salmon":
    "<span>Home</span> <br /> of the Atlantic Salmon",
  "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.":
    "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.",

  "A <span>Family</span> Business": "A <span>Family</span> Business",
  "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.":
    "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.",
  "The brothers, Atli and Regin Gregersen, manage the company today.":
    "The brothers, Atli and Regin Gregersen, manage the company today.",

  //
  // "Sea freight to US": "Sea freight to US",
  // "Air freight New York": "Air freight New York",
  // Freight: "Freight",
  // Production: "Production",
  // "Air freight Shanghai": "Air freight Shanghai",
  // "-emissions per transport type/distance:":
  //   "-emissions per transport type/distance:",
  "kg CO": "kg CO",
  per: "per",
  "kg HOG": "kg HOG",
}
export default tranlations_en
