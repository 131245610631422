const tranlations_fr = {
  // Cookies
  "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.":
    "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy.",
  Accept: "Accept",
  "Learn more": "Learn more",
  "/cookies-policy/": "/fr/cookies-policy/",
  Close: "Close",

  // Header
  "/": "/fr/",
  "/globalg-a-p/": "/fr/globalg-a-p/",
  "GlobalG.A.P": "GlobalG.A.P",
  Certifications: "Certificats",
  "/certifications/": "/fr/certificats/",
  "/our-products/": "/fr/nos-produits/",
  "Our products": "Nos produits",
  "/our-company/": "/fr/notre-compagnie/",
  "Our Company": "NOTRE ENTREPRISE",
  "/contact/": "/fr/contact/",
  Contact: "Contact",
  "/quality-choice/": "/fr/choix-de-qualite/",
  "Quality choice": "CHOIX DE QUALITÉ",
  "/raised-in-the-wild/": "/fr/eleve-en-pleine-mer/",
  "Raised in the wild": "Élevé en pleine mer",
  "Exceptional taste": "Goût exceptionnel",
  "/truly-sustainable-farming/":
    "/fr/elevage-veritablement-issu-du-developpement-durable/",
  "Truly sustainable farming":
    "Élevage véritablement issu du développement durable",
  "/animal-welfare/": "/fr/bien-etre-animal/",
  "Animal welfare": "BIEN-ÊTRE ANIMAL",
  "/low-emission-shipment/": "/fr/transport-avec-de-faibles-emissions-carbone/",
  "Low-emission shipment": "Faible taux d´émission de CO2 Transport",
  "/home-of-the-atlantic-salmon/": "/fr/foyer-du-saumon-de-l-atlantique/",
  "Home of the Atlantic Salmon": "Foyer du Saumon de l´Atlantique",
  "/news/": "/fr/news/",
  News: "News",
  "/jobs/": "/fr/emplois/",
  Jobs: "Emplois",

  // Footer & Contact page
  Phone: "Téléphone",
  Email: "Email",
  Sales: "Vente",
  Administration: "Administration",
  "Message form": "Formulaire de message",
  "All Departments": "All Departments",
  "Hiddenfjord is a trademark of P/F Luna":
    "Hiddenfjord is a trademark of P/F Luna",
  "Mail address": "Mail address",
  "website by hogra.fo": "site internet hogra.fo",
  "Claim form": "Claim form",

  // Contact
  "First name": "First name",
  "Last Name": "Last Name",
  Address: "Address",
  "ZIP code": "ZIP code",
  Town: "Town",
  Country: "Country",
  Telephone: "Telephone",
  Description: "Description",
  "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "I agree to the processing of my personal data by Hiddenfjord, Fútaklettur 1, 360 Sandavágur, Faroe Islands, TIN: FO 418285 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "/privacy-policy/": "/fr/privacy-policy/",
  "Privacy Policy": "Privacy Policy",
  SUBMIT: "SUBMIT",
  "Sending...": "Sending...",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  // News
  Newest: "Newest",
  "Read more": "En savoir plus",
  "See animation": "Voir l’animation",

  // Home
  "Salmon from the Faroe Islands": "Saumon des îles Féroé",
  SUSTAINABILITY: "DÉVELOPPEMENT DURABLE",
  QUALITY: "QUALITÉ",
  Days: "Il y a jours",
  ago: "",

  // Diagrams
  "Sea freight to US": "Fret maritime vers les États-Unis",
  "Air freight New York": "Fret aérien New York",
  "Air freight Shanghai": "Fret aérien Shanghai",
  Freight: "Fret",
  "-emissions per transport type/distance:":
    "-émission de CO2 par type de transport /distance:",
  "edible meat": "de chair consommable",

  "% of Hiddenfjord salmon transported by air":
    "% du saumon Hiddenfjord est transporté par fret aérien",

  "-emissions from meat production":
    "-Émission de CO2 pour la production de chair",

  //
  "The <span>Healthy</span> Choice": "Meilleur choix pour la santé",
  "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.":
    "Le saumon est une viande nutritive, riche en acides gras Omega-3, vitamines et protéines. Le saumon Hiddenfjord ne contient ni antibiotiques, ni hormones.",

  "Raised in the <span>Wild</span>": "Élevé en pleine mer",
  "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.":
    "Notre saumon est élevé dans les eaux pures des îles Féroé. L’élevage dans des enclos en pleine mer avec de forts courants et de hautes vagues maintient le saumon en forme et évite la pollution des fonds marins.",

  "Exceptional <span>Taste</span>": "<span>Goût</span> exceptionnel",
  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.":
    "Le saumon Hiddenfjord a une qualité remarquable. Il a la saveur de l´océan. Elle se prolonge sur le palais avec une agréable onctuosité.",
  "Dave Pasternack, Chef, New York": "Dave Pasternack, Chef, New York",
  "ESCA Restaurant, New York": "ESCA Restaurant, New York",
  "See video": "Voir la vidéo",

  "Truly <span>Sustainable</span> Farming":
    "Élevage véritablement issu du développement durable",
  "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.":
    "Les sites d´élevage du saumon Hiddenfjord sont les plus exposés aux conditions climatiques au monde afin d´assurer des conditions d´élevage optimales. Le développement durable est notre valeur fondamentale et elle se reflète dans toutes nos actions.",

  "<span>Stress-free</span> Harvesting": "Abattage sans stress",
  "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.":
    "Notre concept unique est d´utiliser l´instinct naturel du saumon de nager à contre-courant.  Ils sont guidés avec soin des enclos en pleine mer vers les bassins d´abattage et assommés sans stress. Cette technique permet de donner un meilleur goût ainsi qu´une plus longue durée de conservation.",

  "<span>Low-emission</span> <br /> Shipment":
    "<span>Transport à faible </span> <br /> émission carbon",
  "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.":
    "Le 10 Octobre 2020 nous avons arrêté tout le transport aérien. En remplaçant ce principal contributeur aux émissions de CO<sub>2</sub> avec le transport maritime, nous avons réduit notre empreinte carbone pour le transport à l´international de 94%.",

  "<span>Home</span> <br /> of the Atlantic Salmon":
    "Foyer du Saumon de l´Atlantique",
  "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.":
    "Depuis des milliers d´années, le saumon de l´Atlantique a migré vers les eaux environnant les îles Féroé pour se nourrir et se développer et s´est ainsi adapté à ces eaux pures. C´est le foyer naturel du saumon.",

  "A <span>Family</span> Business": "Une entreprise familiale",
  "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.":
    "Hiddenfjord est une entreprise familiale fondée en 1929. Nous avons une vision idéaliste du développement durable environnemental.",
  "The brothers, Atli and Regin Gregersen, manage the company today.":
    "Les frères Atli et Regin Gregersen gèrent, à ce jour, l´entreprise.",

  //
  // "Sea freight to US": "Sea freight to US",
  // "Air freight New York": "Air freight New York",
  // Freight: "Freight",
  // Production: "Production",
  // "Air freight Shanghai": "Air freight Shanghai",
  // "-emissions per transport type/distance:":
  //   "-emissions per transport type/distance:",
  "kg CO": "kg CO",
  per: "par",
  "kg HOG": "kg de saumon (HOG)",
}
export default tranlations_fr
