import React from "react"
import { Link } from "gatsby"
import $ from "jquery"

import t from "locale"

import IcoFlagEN from "../assets/images/ico-flag-en.svg"
import IcoFlagDE from "../assets/images/ico-flag-de.svg"
import IcoFlagES from "../assets/images/ico-flag-es.svg"
import IcoFlagFR from "../assets/images/ico-flag-fr.svg"
import IcoFlagIT from "../assets/images/ico-flag-it.svg"
class Header extends React.Component {
  state = {
    isMenuOpen: false,
    isHome: false,
    isContact: false,
  }
  handleCloseMenu() {
    this.setState(isMenuOpen => {
      return { isMenuOpen: false }
    })
  }
  componentDidMount() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop()
      if (scroll >= 1) {
        $("header").addClass("shrink")
      } else {
        $("header").removeClass("shrink")
      }
    })

    if (typeof window !== "undefined") {
      if (window.location.pathname === "/") {
        this.setState(isHome => {
          return { isHome: true }
        })
      }
      if (
        window.location.href.indexOf("contact") > -1 ||
        window.location.href.indexOf("kontakt") > -1 ||
        window.location.href.indexOf("news") > -1 ||
        window.location.href.indexOf("policy") > -1 ||
        window.location.href.indexOf("privacy-notice") > -1 ||
        window.location.href.indexOf("media") > -1
      ) {
        this.setState(isContact => {
          return { isContact: true }
        })
      }
    }
  }

  render() {
    const { isMenuOpen } = this.state
    const { isHome } = this.state
    const { isContact } = this.state
    const { isNews } = this.props
    // const { language } = this.props
    const { lang } = this.props
    const { translationEN } = this.props
    const { translationDE } = this.props
    const { translationES } = this.props
    const { translationIT } = this.props
    const { translationFR } = this.props

    if (typeof window !== "undefined") {
      if (isMenuOpen === true) {
        document.body.classList.add("appleFix")
        $(".hamburger-nav").addClass("opened")
      } else {
        document.body.classList.remove("appleFix")
        $(".hamburger-nav").removeClass("opened")
      }
    }

    return (
      <header
        className={`header ${!isHome ? "" : "header-home"} ${
          isContact || isNews === true ? "header--contact" : ""
        }`}
      >
        <div className="header__inner">
          <Link to={t("/", lang)} className="navbar-brand">
            <img src={require("../assets/images/logo-green.svg")} alt="" />
          </Link>
          <nav className="desktop-nav">
            <ul>
              <li>
                <Link to={t("/our-products/", lang)}>
                  {t("Our products", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/our-company/", lang)}>
                  {t("Our Company", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/certifications/", lang)}>
                  {t("Certifications", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/contact/", lang)}>{t("Contact", lang)}</Link>
              </li>
            </ul>
            <div className="header__lang">
              {lang === "en" && <img src={IcoFlagEN} alt="" />}
              {lang === "de" && <img src={IcoFlagDE} alt="" />}
              {lang === "es" && <img src={IcoFlagES} alt="" />}
              {lang === "it" && <img src={IcoFlagIT} alt="" />}
              {lang === "fr" && <img src={IcoFlagFR} alt="" />}
              <div>
                {translationEN && (
                  <Link to={translationEN}>
                    <img src={IcoFlagEN} alt="" />
                  </Link>
                )}
                {translationDE && (
                  <Link to={translationDE}>
                    <img src={IcoFlagDE} alt="" />
                  </Link>
                )}
                {translationES && (
                  <Link to={translationES}>
                    <img src={IcoFlagES} alt="" />
                  </Link>
                )}
                {translationIT && (
                  <Link to={translationIT}>
                    <img src={IcoFlagIT} alt="" />
                  </Link>
                )}
                {translationFR && (
                  <Link to={translationFR}>
                    <img src={IcoFlagFR} alt="" />
                  </Link>
                )}
              </div>
            </div>
          </nav>
          <button
            type="button"
            aria-label="Open Menu"
            className={`hamburger hamburger--squeeze ${
              isMenuOpen ? "is-active" : ""
            }`}
            onClick={() =>
              this.setState(prevState => {
                return { isMenuOpen: !prevState.isMenuOpen }
              })
            }
          >
            {Array(4)
              .fill(null)
              .map((item, index) => (
                <span key={index} />
              ))}
          </button>
          <nav className="hamburger-nav">
            <div className="header__lang">
              <div>
                {translationEN && (
                  <Link to={translationEN}>
                    <img src={IcoFlagEN} alt="" />
                  </Link>
                )}
                {translationDE && (
                  <Link to={translationDE}>
                    <img src={IcoFlagDE} alt="" />
                  </Link>
                )}
                {translationES && (
                  <Link to={translationES}>
                    <img src={IcoFlagES} alt="" />
                  </Link>
                )}
                {translationIT && (
                  <Link to={translationIT}>
                    <img src={IcoFlagIT} alt="" />
                  </Link>
                )}
                {translationFR && (
                  <Link to={translationFR}>
                    <img src={IcoFlagFR} alt="" />
                  </Link>
                )}
              </div>
            </div>
            <ul>
              <li>
                <Link to={t("/our-products/", lang)}>
                  {t("Our products", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/our-company/", lang)}>
                  {t("Our Company", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/quality-choice/", lang)}>
                  {t("Quality choice", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/raised-in-the-wild/", lang)}>
                  {t("Raised in the wild", lang)}
                </Link>
              </li>
              <li>
                <Link to={`${t("/", lang)}#exceptional-taste`}>
                  {t("Exceptional taste", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/truly-sustainable-farming/", lang)}>
                  {t("Truly sustainable farming", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/animal-welfare/", lang)}>
                  {t("Animal welfare", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/low-emission-shipment/", lang)}>
                  {t("Low-emission shipment", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/home-of-the-atlantic-salmon/", lang)}>
                  {t("Home of the Atlantic Salmon", lang)}
                </Link>
              </li>
              <li>
                <Link to={t("/certifications/", lang)}>
                  {t("Certifications", lang)}
                </Link>
              </li>
              {lang === "en" && (
                <li>
                  <Link to={t("/sustainability-report/", lang)}>
                    SUSTAINABILITY REPORT
                  </Link>
                </li>
              )}
              {lang === "en" && (
                <li>
                  <Link to={t("/news/", lang)}>{t("News", lang)}</Link>
                </li>
              )}
              <li>
                <Link to={t("/jobs/", lang)}>{t("Jobs", lang)}</Link>
              </li>
              <li>
                <Link to={t("/contact/", lang)}>{t("Contact", lang)}</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
